<template>
	<div v-if="loaded">
		<v-card dark color="secondary" class="mb-2 pl-2 pr-2">
			<v-row>
				<v-col cols="12" xl="3" lg="3" md="3" sm="6">
					<div class="form-group">
						<small class="control-label">ESTABLECIMIENTO</small>
						<el-select v-model="form.establishment_id" @change="loadAll" size="small">
							<el-option
								v-for="option in establishments"
								:key="option.id"
								:value="option.id"
								:label="option.name"
							></el-option>
						</el-select>
					</div>
				</v-col>
				<v-col cols="12" xl="3" lg="3" md="3" sm="6">
					<div class="form-group">
						<small class="control-label">PERIODO</small>
						<el-select v-model="form.period" @change="changePeriod" size="small">
							<el-option key="month" value="month" label="Por mes"></el-option>
							<el-option
								key="between_months"
								value="between_months"
								label="Entre meses"
							></el-option>
							<el-option key="date" value="date" label="Por fecha"></el-option>
							<el-option
								key="between_dates"
								value="between_dates"
								label="Entre fechas"
							></el-option>
						</el-select>
					</div>
				</v-col>

				<template v-if="form.period === 'month' || form.period === 'between_months'">
					<v-col cols="12" xl="3" lg="3" md="3" sm="6">
						<div class="form-group">
							<small class="control-label">MES DE</small>
							<el-date-picker
								size="small"
								v-model="form.month_start"
								type="month"
								@change="changeDisabledMonths"
								value-format="yyyy-MM"
								format="MM/yyyy"
								:clearable="false"
							></el-date-picker>
						</div>
					</v-col>
				</template>
				<template v-if="form.period === 'between_months'">
					<v-col cols="12" xl="3" lg="3" md="3" sm="6">
						<div class="form-group">
							<small class="control-label">MES AL</small>
							<el-date-picker
								size="small"
								v-model="form.month_end"
								type="month"
								:picker-options="pickerOptionsMonths"
								@change="loadDynamicData"
								value-format="yyyy-MM"
								format="MM/yyyy"
								:clearable="false"
							></el-date-picker>
						</div>
					</v-col>
				</template>
				<template v-if="form.period === 'date' || form.period === 'between_dates'">
					<v-col cols="12" xl="3" lg="3" md="3" sm="6">
						<div class="form-group">
							<small class="control-label">FECHA DEL</small>
							<el-date-picker
								size="small"
								v-model="form.date_start"
								type="date"
								@change="changeDisabledDates"
								value-format="yyyy-MM-dd"
								format="dd/MM/yyyy"
								:clearable="false"
							></el-date-picker>
						</div>
					</v-col>
				</template>
				<template v-if="form.period === 'between_dates'">
					<v-col cols="12" xl="3" lg="3" md="3" sm="6">
						<div class="form-group">
							<small class="control-label">FECHA AL</small>
							<el-date-picker
								size="small"
								v-model="form.date_end"
								type="date"
								:picker-options="pickerOptionsDates"
								@change="loadDynamicData"
								value-format="yyyy-MM-dd"
								format="dd/MM/yyyy"
								:clearable="false"
							></el-date-picker>
						</div>
					</v-col>
				</template>
			</v-row>
		</v-card>

		<v-row dense>
			<v-col cols="12" xl="3" lg="3" md="3" sm="6">
				<div
					class="feature-v2-wrap section-gap overlay-section pb-0 pt-xl-12 pt-md-12 pt-sm-0"
				>
					<div class="emb-card pa-4">
						<div class="feature-section-v2-list">
							<v-btn class="primary medium mx-0" icon>
								<v-icon>mdi-cart</v-icon>
							</v-btn>

							<div class=" ml-6 feature-section-v2-content">
								<h4 class="mb-2 font-weight-bold text-right">
									{{ current_totals.total_income }}
								</h4>
								<p class="mb-0">VENTAS DEL DÍA</p>
							</div>
						</div>
					</div>
				</div>
			</v-col>
			<v-col cols="12" xl="3" lg="3" md="3" sm="6">
				<div
					class="feature-v2-wrap section-gap overlay-section pb-0 pt-xl-12 pt-md-12 pt-sm-0"
				>
					<div class="emb-card pa-4">
						<div class="feature-section-v2-list">
							<v-btn class="primary medium mx-0" icon>
								<v-icon>mdi-arrow-left-bold-circle</v-icon>
							</v-btn>

							<div class=" ml-6 feature-section-v2-content">
								<h4 class="mb-2 font-weight-bold text-right">
									{{ current_totals.total_expense }}
								</h4>
								<p class="mb-0">GASTOS DEL DÍA</p>
							</div>
						</div>
					</div>
				</div>
			</v-col>
			<v-col cols="12" xl="3" lg="3" md="3" sm="6">
				<div
					class="feature-v2-wrap section-gap overlay-section pb-0 pt-xl-12 pt-md-12 pt-sm-0"
				>
					<div class="emb-card pa-4">
						<div class="feature-section-v2-list">
							<v-btn class="primary medium mx-0" icon>
								<v-icon>mdi-credit-card</v-icon>
							</v-btn>

							<div class=" ml-6 feature-section-v2-content">
								<h4 class="mb-2 font-weight-bold text-right">
									{{ current_totals.payment_card }}
								</h4>
								<p class="mb-0">PAGOS CON TARJETA</p>
							</div>
						</div>
					</div>
				</div>
			</v-col>
			<v-col cols="12" xl="3" lg="3" md="3" sm="6">
				<div
					class="feature-v2-wrap section-gap overlay-section pb-0 pt-xl-12 pt-md-12 pt-sm-0"
				>
					<div class="emb-card pa-4">
						<div class="feature-section-v2-list">
							<v-btn class="primary medium mx-0" icon>
								<v-icon>mdi-food</v-icon>
							</v-btn>

							<div class=" ml-6 feature-section-v2-content">
								<h4 class="mb-2 font-weight-bold text-right">{{ current_totals.num_rentals }}</h4>
								<p class="mb-0">N° DE ALQUILERES</p>
							</div>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>

		<v-row dense>
			<v-col cols="12" xl="6" lg="6" md="6" sm="12">
				<v-card color="secondary">
					<v-card-text>
						<p class="pt-2 text-center">TOTAL DE VENTAS POR MES DEL AÑO {{ year }}</p>
						<GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" xl="6" lg="6" md="6" sm="12">
				<v-card color="secondary">
					<v-card-text>
						<p class="pt-2 text-center">DIAS CON CON MAS VENTAS EN EL MES</p>
						<GChart type="ColumnChart" :data="chartData2" :options="chartOptions" />
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12" xl="6" lg="6" md="6" sm="12">
				<v-card color="secondary">
					<v-card-text>
						<p class="pt-2 text-center">BALANCE INGRESOS / EGRESOS</p>
						<GChart type="PieChart" :data="chartDataPie" :options="chartOptions" />
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" xl="6" lg="6" md="6" sm="12">
				<v-card color="secondary">
					<v-card-text>
						<p class="pt-2 text-center">HORA PUNTA</p>
						<GChart type="AreaChart" :data="chartDataLine" :options="chartOptions" />
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12" xl="6" lg="6" md="6" sm="12">
				<v-card color="secondary">
					<v-card-text>
						<p class="pt-2 text-center">MÁS VENDIDOS</p>
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-right">
											N°
										</th>
										<th class="text-left">
											PRODUCTO
										</th>
										<th class="text-right">
											CANTIDAD
										</th>
										<th class="text-right">
											IMPORTE
										</th>
										<th class="text-right">
											% VENTAS
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in itemsBySales" :key="index">
										<td class="text-right">{{ index + 1 }}</td>
										<td>{{ item.name }}</td>
										<td class="text-right">{{ item.quantity }}</td>
										<td class="text-right">S/ {{ item.total }}</td>
										<td class="text-right">{{ item.percentage }} %</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" xl="6" lg="6" md="6" sm="12">
				<v-card color="secondary">
					<v-card-text>
						<p class="pt-2 text-center">TOP CLIENTES</p>
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-right">
											N°
										</th>
										<th class="text-left">
											CLIENTE
										</th>
										<th class="text-right">
											CANTIDAD
										</th>
										<th class="text-right">
											IMPORTE
										</th>
										<th class="text-right">
											% VENTAS
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in topCustomers" :key="index">
										<td class="text-right">{{ index + 1 }}</td>
										<td>{{ item.name }}</td>
										<td class="text-right">{{ item.quantity }}</td>
										<td class="text-right">S/ {{ item.total }}</td>
										<td class="text-right">{{ item.percentage }} %</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { GChart } from 'vue-google-charts';

import dayjs from 'dayjs';

export default {
	components: {
		GChart,
	},
	data() {
		return {
			resource: 'dashboard',
			selectedChartData: null,
			chartData: null,
			chartData2: null,
			chartDataPie: null,
			chartDataLine: null,
			form: {},
			company: {},
			establishments: [],
			current_totals: {
				num_rentals: 0.0,
				total_income: 0.0,
				total_expense: 0.0,
				payment_card: 0.0,
			},
			itemsBySales: [],
			topCustomers: [],
			loaded: false,
			year: dayjs().format('YYYY'),
			now: dayjs().format('YYYY-MM-DD'),
			date_of_alert: dayjs().format('YYYY-MM-DD'),
			pickerOptionsDates: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return this.form.date_start > time;
				},
			},
			pickerOptionsMonths: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM');
					return this.form.month_start > time;
				},
			},
			chartOptions: {
				is3D: true,
				ars: 'horizontal', // Required for Material Bar Charts.
				hAxis: { format: 'decimal' },
				colors: ['#e21e42', '#00D0BD', '#20c997'],
				chart: {
					title: 'Estadistica de ventas',
					subtitle: 'Sales, Expenses, and Profit: 2014-2017',
				},
				legend: { position: 'bottom' },
				backgroundColor: '#212e36',
				color: '#fff',
			},
		};
	},
	async created() {
		await this.initForm();
		await this.getFilters();
		await this.loadDynamicData();
		await this.loadStaticData();
	},
	methods: {
		initForm() {
			this.form = {
				establishment_id: null,
				period: 'between_dates',
				date_start: dayjs()
					.startOf('month')
					.format('YYYY-MM-DD'),
				date_end: dayjs().format('YYYY-MM-DD'),
				month_start: dayjs()
					.startOf('year')
					.format('YYYY-MM'),
				month_end: dayjs()
					.endOf('year')
					.format('YYYY-MM'),
			};
		},
		async getFilters() {
			await this.$http.get(`/${this.resource}/filters`).then((response) => {
				this.establishments = response.data.establishments;
				this.form.establishment_id =
					this.establishments.length > 0 ? this.establishments[0].id : null;
			});
		},
		async loadAll() {
			await this.loadDynamicData();
			await this.loadStaticData();
		},
		loadStaticData() {
			this.loaded = false;
			this.$http.post(`/${this.resource}/static-data`, this.form).then((response) => {
				let line = response.data.data.sales_by_year;
				let line2 = response.data.data.sales_by_day;
				let line3 = response.data.data.peak_time;

				this.chartData = line.data;
				this.chartData2 = line2.data;
				this.chartDataLine = line3;

				this.current_totals = response.data.data.current_totals;

				// 
				// this.top_customers = response.data.data.top_customers;
				this.company = response.data.data.company;

				this.date_of_alert = dayjs(this.company.date_of_alert).format('YYYY-MM-DD');

				this.loaded = true;
			});
		},
		loadDynamicData() {
			this.$http.post(`/${this.resource}/dynamic-data`, this.form).then((response) => {
				this.chartDataPie = response.data.data.cashFlowByMonth;
				this.itemsBySales = response.data.data.itemsBySales;
				this.topCustomers = response.data.data.topCustomers;
			});
		},
		changeDisabledDates() {
			if (this.form.date_end < this.form.date_start) {
				this.form.date_end = this.form.date_start;
			}
			this.loadAll();
		},
		changeDisabledMonths() {
			if (this.form.month_end < this.form.month_start) {
				this.form.month_end = this.form.month_start;
			}
			this.loadAll();
		},
		changePeriod() {
			if (this.form.period === 'month') {
				this.form.month_start = dayjs().format('YYYY-MM');
				this.form.month_end = dayjs().format('YYYY-MM');
			}
			if (this.form.period === 'between_months') {
				this.form.month_start = dayjs()
					.startOf('year')
					.format('YYYY-MM'); //'2019-01';
				this.form.month_end = dayjs()
					.endOf('year')
					.format('YYYY-MM');
			}
			if (this.form.period === 'date') {
				this.form.date_start = dayjs().format('YYYY-MM-DD');
				this.form.date_end = dayjs().format('YYYY-MM-DD');
			}
			if (this.form.period === 'between_dates') {
				this.form.date_start = dayjs()
					.startOf('month')
					.format('YYYY-MM-DD');
				this.form.date_end = dayjs().format('YYYY-MM-DD');
			}
			this.loadAll();
		},
	},
};
</script>
